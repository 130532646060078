<template>
  <div class="payment-container">
    <!-- <navbar @changeLang="onChangeLang"></navbar> -->
    <div v-if="!isLoading" class="body-max-width">
      <div class="fs-18 flex-mid">
        {{ $t("pay") }}
      </div>

      <div class="list-box">
        <div class="summary-header">
          <div class="title">รายการสั่งซื้อ</div>
        </div>
        <hr width="100%" />
        <div
          v-for="item in selectPackage"
          :key="item.id"
          class="summary-package"
        >
          <div class="summary-name">
            {{ item.categoryName }}
          </div>
          <div class="summary-list-price">
            {{ item.listPrice }}
          </div>
          <div class="summary-net-price">
            {{ item.netPrice }}
          </div>
        </div>
        <hr width="100%" />
        <div class="total-header">
          <div class="title">ราคารวม</div>
          <div class="price">
            {{ totalPrice }}
          </div>
        </div>
      </div>
      <!-- payment  method -->
      <div class="method-box">
        <div class="summary-header">
          <div class="title">
            {{ $t("paymentMethod") }}
          </div>
        </div>
        <hr width="100%" />
        <div class="summary-package">
          <el-radio-group v-model="radioPayment" class="ml-4">
            <el-radio label="qrCode" size="large" class="summary-name ml-10">{{
              $t("qrCode")
            }}</el-radio>
            <el-radio
              label="creditCard"
              size="large"
              class="summary-name ml-10"
              >{{ $t("creditCard") }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>

      <div class="payment-button" @click="submitPayment">
        <div class="text flex-mid">{{ $t("pay") }}</div>
      </div>

      <!-- <div v-if="radioPayment == 'qrCode'">
                <form method="POST" action="/checkout">
                    <component :is="'script'" type="text/javascript"
                        src="https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js"
                        data-apikey="pkey_test_21202R91a3hMaT2l7YMTxNaNAsEAy8aTDWNEu" data-amount="30.00"
                        data-payment-methods="qr" data-order-id="order_test_21202289aeb9d77df4f81b85dd4b45b0b142d">
                    </component>
                </form>
            </div>
            <div v-else>
                <form method="POST" action="/checkout">
                    <component :is="'script'" type="text/javascript"
                        src="https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js"
                        data-apikey="pkey_test_21202R91a3hMaT2l7YMTxNaNAsEAy8aTDWNEu" data-amount="74.00"
                        data-currency="THB" data-payment-methods="card" data-name="Anythings would works!"
                        data-mid="401991211230001">
                    </component>
                </form>

            </div> -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import Navbar from "@/components/Navbar.vue";
import { useWindowSize } from "@vueuse/core";
export default defineComponent({
  name: "SelectPayment",
  // components: {
  //     Header: require("@/components/HomeNav.vue").default,
  // },
  // components: {
  //     Navbar,
  // },
  data() {
    return {
      accessToken: "",
      isLoading: true,
      lang: "th",
      totalPrice: 0,
      selectPackage: "",
      radioPayment: "qrCode",
      checkReload: false,
      orderId: "",
      merchantId: "",
    };
  },
  mounted() {
    this.access_token = window.localStorage.getItem("access_token");
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
    console.log("program page params", this.$route);
    // window.localStorage.setItem("checkReload", this.checkReload);
    this.totalPrice = window.localStorage.getItem("totalPrice");
    this.orderId = window.localStorage.getItem("orderId");
    this.selectPackage = JSON.parse(window.localStorage.getItem("selectPack"));
    console.log("orderId", this.orderId);
    this.getMids();

    // window.localStorage.setItem("checkReload", this.checkReload);
    // this.checkReload = window.localStorage.getItem("checkReload");
    // console.log(typeof (this.checkReload))
    // if (this.checkReload == "false") {
    //     this.reloadPage()
    //     console.log("checkReload", this.checkReload)
    // }

    // this.totalPrice = this.$route.params.totalPrice;
    // this.selectPackage = JSON.parse(this.$route.params.selectPack)

    this.isLoading = false;
  },
  methods: {
    redirect(redirect) {
      window.location.href = redirect;
    },
    // reloadPage() {
    //     this.checkReload = true
    //     window.localStorage.setItem("checkReload", this.checkReload);
    //     window.location.reload()
    // },
    getMids() {
      axios
        .post(
          process.env.VUE_APP_PAYMENTAPI_URL +
            "/well-payment-api/master-data/get-merchant-id",
          {},
          {
            headers: {
              // 'x-api-key': HEALTH_DIARY_API_KEY,
              Authorization: "Bearer " + this.access_token,
              accept: "application/json; charset=UTF-8",
              "Accept-Language": this.lang,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("Get mids",res.data)
          this.merchantId = res.data["his-dcc"];
          console.log("merchantId", this.merchantId);
        })
        .catch((error) => {
          this.Loading = false;
          if (error.response == null) {
            this.errmsg = error;
          } else if (error.response.status == 403) {
            window.location = "/signin";
          } else {
            if (error.response.data.error) {
              this.errmsg = error.response.data.error;
            } else {
              this.errmsg = error;
            }
          }
        });
    },
    submitPayment() {
      console.log(this.radioPayment);
      if (this.radioPayment == "qrCode") {
        axios
          .post(
            process.env.VUE_APP_PAYMENTAPI_URL +
              "/well-payment-api/gateway/setup-payment",
            {
              setupPaymentRequestBean: {
                currency: "THB",
                description: "TotalHealth Package",
                orderDetailBean: [
                  {
                    source: "TOTALSOL",
                    description: "TotalHealth Package",
                    orderNumber: this.orderId,
                    purchasePrice: parseInt(this.totalPrice),
                    quantity: 1,
                    deliveryFee: 0,
                  },
                ],
                paymentMethod: "qr",
                paymentType: "qr",
                rememberCard: false,
                token: "",
                merchantId: "451331211230001",
              },
            },
            {
              headers: {
                // 'x-api-key': HEALTH_DIARY_API_KEY,
                Authorization: "Bearer " + this.access_token,
                accept: "application/json; charset=UTF-8",
                "Accept-Language": this.lang,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            window.localStorage.setItem("totalPrice", this.totalPrice);
            window.localStorage.setItem("checkReload", this.checkReload);
            window.localStorage.setItem("kBankOrderId", res.data.setupResponse);

            this.$router.push({
              name: "QRPayment",
              query: { lang: this.lang },
            });
          })
          .catch((error) => {
            this.Loading = false;
            if (error.response == null) {
              this.errmsg = error;
            } else if (error.response.status == 403) {
              window.location = "/signin";
            } else {
              if (error.response.data.error) {
                this.errmsg = error.response.data.error;
              } else {
                this.errmsg = error;
              }
            }
          });
      } else {
        window.localStorage.setItem("totalPrice", this.totalPrice);
        window.localStorage.setItem("checkReload", this.checkReload);
        window.localStorage.setItem("orderId", this.orderId);
        this.$router.push({
          name: "CreditCardPayment",
          query: { lang: this.lang },
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
$color-on-hover: #fbfff5;

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.payment-container {
  display: block;
  margin: auto;
  margin-top: 5rem;
  padding: 0 1.5rem;
  max-width: 500px;

  .list-box {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
    background: #ffffff;
    padding: 1.5rem 1rem 1.2rem 1rem;
    border-radius: 15px;
    filter: drop-shadow(-5px -5px 5px rgba(232, 232, 232, 0.25))
      drop-shadow(5px 5px 10px rgba(163, 162, 162, 0.25));

    .summary-header {
      width: 100%;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .summary-package {
      font-size: 1.8rem;
      width: 100%;
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 70% 15% 15%;

      .summary-name {
        font-size: 1.4rem;
      }

      .summary-list-price {
        font-size: 1.4rem;
        text-decoration: line-through;
        color: grey;
      }

      .summary-net-price {
        font-size: 1.4rem;
      }
    }

    .total-header {
      width: 100%;
      display: grid;
      grid-template-columns: 85% auto;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
      }

      .price {
        font-size: 1.4rem;
        left: 0;
      }
    }
  }

  .method-box {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 3rem;
    background: #ffffff;
    padding: 1.5rem 1rem 1.2rem 1rem;
    border-radius: 15px;
    filter: drop-shadow(-5px -5px 5px rgba(232, 232, 232, 0.25))
      drop-shadow(5px 5px 10px rgba(163, 162, 162, 0.25));

    .summary-header {
      width: 100%;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    .summary-package {
      font-size: 1.8rem;
      width: 100%;
      margin-top: 1rem;

      .summary-name {
        font-size: 1.4rem;
      }
    }

    .total-header {
      width: 100%;
      display: grid;
      grid-template-columns: 85% auto;

      .title {
        font-size: 1.6rem;
        font-weight: 600;
      }

      .price {
        font-size: 1.4rem;
        left: 0;
      }
    }
  }

  .payment-button {
    height: 5rem;
    background-color: #6db557;
    width: 40%;
    margin: auto;
    border-radius: 15px;
    filter: drop-shadow(-5px -5px 5px rgba(232, 232, 232, 0.25))
      drop-shadow(5px 5px 10px rgba(163, 162, 162, 0.25));

    .text {
      font-size: 1.6rem;
      line-height: 5rem;
      color: #ffffff;
    }
  }
}

.summary-box {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 10rem;
  background: #ffffff;
  padding: 1.5rem 1rem 1.2rem 1rem;
  border-radius: 15px;
  filter: drop-shadow(-5px -5px 5px rgba(232, 232, 232, 0.25))
    drop-shadow(5px 5px 10px rgba(163, 162, 162, 0.25));

  .summary-header {
    width: 100%;
    padding-left: 1rem;
    display: grid;
    grid-template-columns: 70% auto;
    padding: 1rem;

    .title {
      font-size: 1.6rem;
      font-weight: 600;
    }

    .addMenu {
      font-size: 1.2rem;
      font-weight: 500;
      color: #2d7617;
      margin: auto;
    }
  }

  .summary-menu {
    font-size: 1.8rem;
    width: 100%;
    padding-left: 1rem;
    display: grid;
    grid-template-columns: 10% auto;
    gap: 0.5rem;
    margin-top: 2rem;

    .summary-number {
      font-size: 1.2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      background: #6db557;
      text-align: center;
      color: #ffffff;
      font-weight: 600;
    }

    .summary-name {
      font-size: 1.2rem;
    }
  }

  .summary-price {
    margin: auto;
    font-size: 1.8rem;
    width: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1.6rem;

    .label {
      text-align: left;
    }

    .price {
      text-align: right;
      // right: 0;
    }
  }
}

.hospital-name {
  font-size: 1.4rem;
}

.fast-pay-button {
  width: 30%;
  height: 4rem;
  background-color: #429928;
  color: #ffffff;
  font-size: 1.8rem;
  text-align: center;
  border-radius: 2rem;
}
</style>
